import React from 'react';
import MiCredencialPrincipal from 'components/miCredencialPrincipal';


const CredencialCaja = () => {
    return (
       <MiCredencialPrincipal />
    );
};

export default CredencialCaja;