import React, { useEffect, useState }  from 'react';
import ReactDom from 'react-dom';
import {  Button, Tooltip, CircularProgress} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {useLocation, useHistory, useParams} from 'react-router-dom';
import { GET_LINK_ERROR ,GET_LINK_PDF} from 'constants/endpoints';
import MiCredencialInterna from 'components/miCredencialInterna';
import getCredencial from 'services/getCredencial';
import MiCredencialError from 'components/miCredencialError';


import Modal from 'react-modal';




const useStyles = makeStyles((theme) => ({
  errorMesage: {
    fontWeight: 'lighter',
    color: 'red',
  },
  img: {

  }, 
  buttons:{
    backgroundColor: '#1a4821',
    color: 'white'
}

}));

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#root');

const MiCredencial = () => {
  let history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let imageId = query.get('token');
  const [image, setimages] = useState()
 // const [pdf, setPdf] = useState()
  const [imagenSeleccionada, setImagenSeleccionada] = useState(0); // Inicia con la primera imagen
  const [modalAbierto, setModalAbierto] = useState(false); // Controla si el modal está abierto o no
  const urlError = GET_LINK_ERROR
  
  const floatingStyle = {
    position: 'center',
    margin:'4% 0 0 0',
    border: '5px solid #1E772D',
    borderRadius: '15px',
    padding: '40px',
    backgroundColor: 'white',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
    textAlign: 'center',
    
    marginLeft: 'auto',
    marginRight: 'auto',
    fontFamily:'Verdana',
    fontSize: 'large',
    textAlign:'center',
    fontWeight: 'bold'
  };
  const linkStyle = {
    textDecoration: 'none',
    fontWeight: 'bold',
  };
  //const { imageId } = useParams();

  //let decodeCodUsu = decodeURIComponent(imageId);
  //console.log(decodeCodUsu)
  useEffect(function () {
    getCredencial({keyword: imageId}).then(images => setimages(images))
    //getCredencial({keyword: decodeCodUsu}).then(images => setimages(images))
  },[])


  //const image = images.find(img => img.id === parseInt(imageId));
  if (image == undefined) {
    return <div style={{textAlign: "center", fontFamily: "Verdana",fontSize: "larger"}}><div>Obteniendo Información</div><div><CircularProgress /></div></div>
     //return <div>Obteniendo Información</div>
  }else
  {
  
    if (!image.results) {
      //return <MiCredencialError datosError={image}/>
      if (image.iderror == 2 || image.iderror == 3 || image.iderror == 4){
      return <div style={floatingStyle}>
        <div>{image.error} <a style={linkStyle} href={urlError}>AQUI</a> - DENTRO DE LAS PRÓXIMAS 72 H HÁBILES PODRÁ VISUALIZAR SU CREDENCIAL</div>
        </div>;
        }
        else{
          return <div style={floatingStyle}>
          <div>{image.error} </div>
          </div>;
        }
    }
    else
    {
      
      const imagenes = [
        image.data.frente,
        image.data.dorso
      ];

      const abrirModal = () => {
        setModalAbierto(true);
      };
    
      const cerrarModal = () => {
        setModalAbierto(false);
      };
    
      const siguienteImagen = () => {
        if (imagenSeleccionada < imagenes.length - 1) {
          setImagenSeleccionada(imagenSeleccionada + 1);
        }
      };
    
      const imagenAnterior = () => {
        if (imagenSeleccionada > 0) {
          setImagenSeleccionada(imagenSeleccionada - 1);
        }
      };

      const handleVerCredencial= (url) =>{
        
       
        var encodeToken = encodeURIComponent(imageId);
       // window.open(`https://calidadonline2:8311/PdfCredencial/${imageId}`, '_blank');
        window.open(`${GET_LINK_PDF}?tk=${encodeToken}`, '_blank');
        
      }

      const irAOtroComponente = () => {
          // Redirige a la ruta de OtroComponente `/otro-componente/${parametro}`
            var encodeToken = encodeURIComponent(imageId);
            history.push(`../MiCredencialInterna?token=${encodeToken}`,{data : imagenes});
        };

      const ESFrente = () =>
      {
        if  (imagenSeleccionada = 1) {
          ESFrente = "disabled"
        }
      };

      return (
        <div style={{
          textAlign: "center",
          maxWidth: "450px",
          margin: "-50px auto",
          border: "1px solid #e6e6e6"
          
          //padding: "40px 25px",
          //marginTop: "50px"
        }}>
          
          <div style={{
          textAlign: "center",
          maxWidth: "450px",
          //margin: "auto",
        
          marginBottom: "50px"
          //padding: "40px 25px",
          //marginTop: "50px"
        }}>
            <img src={image.data.qr} alt="Base64" />
          </div>
          <div style={{
          textAlign: "center",
          maxWidth: "450px",
          margin: "0 auto",
          border: "1px solid #e6e6e6",
          //padding: "40px 25px",
          //marginTop: "50px"
        }}>
          <Button color="success" onClick={() => irAOtroComponente()} variant="contained" fullWidth >
          Ver credencial válida
          </Button>

          </div>

          <div style={{
          textAlign: "center",
          maxWidth: "450px",
          margin: "0 auto",
          border: "1px solid #e6e6e6"
          //padding: "40px 25px",
          //marginTop: "50px"
        }}>
            <Button color="success" onClick={() => handleVerCredencial()} variant="contained" fullWidth >
            Descargar
            </Button>
        <Modal 
          isOpen={modalAbierto}
          onRequestClose={cerrarModal}
          contentLabel="Galería de imágenes"
          style={customStyles}
          // ver las demas propiedades de modal
        >
        
          <div className="modal-imagen" style={{ position: 'relative',margin: "0 auto", maxWidth: "450px" }}>
            <img src={imagenes[imagenSeleccionada]} alt="Imagen seleccionada" 
            style={{ width: '100%', height: 'auto' }} 
            />
            <Tooltip title="Cerrar" arrow>
            <Button onClick={cerrarModal} style={{ position: 'absolute', top: '-8%', right: '-4%'  }}>X</Button>
            </Tooltip>
            <Tooltip title="Frente" arrow>
              <Button onClick={imagenAnterior} style={{ position: 'absolute', top: '50%', left: '-4%', transform: 'translateY(-50%)' }}>←
            </Button>
            </Tooltip>
            <Tooltip title="Dorso" arrow>  
            <Button onClick={siguienteImagen} style={{ position: 'absolute', top: '50%', right: '-4%', transform: 'translateY(-50%)' }}>→</Button>
            </Tooltip>
            </div>
        
        </Modal>
          <div>Si desea modificar sus datos, haga click <a style={linkStyle} href={urlError}>AQUI</a> - Dentro de las próximas 72 horas hábiles podrá visualizar su credencial actualizada.</div>
        
          </div>
        </div>
      );
    }
  }
};

export default MiCredencial;